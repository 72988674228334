import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { isEqual } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { debounceTime, delay, skip, take, takeUntil } from 'rxjs/operators';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatButton } from '@angular/material/button';
import { AvailableLanguages, fullDateFormat } from '@constants';
import { LocalStorageService } from '@core/local-storage.service';
import {
  AllDeviceInfo,
  GUIDE_NAME,
  GuideFinishedOpts,
  PersonalDetails,
  PROCESS_CATEGORY,
  User,
  UserProcess,
} from '@interfaces';
import { LayoutService } from '@layout/layout.service';
import { customDateValidator, isBooleanValidator } from '@shared/custom-validators';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { IntroModalService, MODAL_MESSAGE } from '@shared/intro-modal/intro-modal.service';
import { ModeChangedModalService } from '@shared/mode-changed-modal/mode-changed-modal.service';

type DateFormats = {
  [key: string]: string;
};

@Component({
  selector: 'app-temp-premit-form',
  templateUrl: './temp-premit-form.component.html',
  styleUrls: ['./temp-premit-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class TempPremitFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('mainStepper') mainStepper: MatStepper;
  @ViewChild('stepperContainer') stepperContainer: ElementRef;
  @ViewChild('saveAndProceedButton') saveAndProceedButton: MatButton;

  @Input() user: User;
  @Input() userProcess: UserProcess;
  @Input() currentLang: AvailableLanguages;
  @Input() loadingUserProcess: boolean;
  @Input() errorMessage: string;
  @Input() mode: 'foreigner' | 'employee';

  @Input() correctlyFilledSteps: number;
  @Input() personalDataForm: FormGroup;
  @Input() percentageOfCompletness: number;
  @Input() totalStepsInForm: number;
  @Input() dateFormatsForFields: DateFormats;

  @Output() updateDetails = new EventEmitter<PersonalDetails>();
  @Output() confirmDetails = new EventEmitter<void>();
  @Output() confirmVoivoChange = new EventEmitter<void>();
  @Output() toggleVerificationOfPersonalDetails = new EventEmitter();

  public stepper: MatStepper;
  public GUIDE_NAME = GUIDE_NAME;
  public deviceInfo: AllDeviceInfo;

  constructor(
    private readonly translateService: TranslateService,
    private readonly layoutService: LayoutService,
    private readonly introModal: IntroModalService,
    private readonly localStorage: LocalStorageService,
    private readonly modeChangedModal: ModeChangedModalService,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });
  }

  public async ngAfterViewInit(): Promise<void> {
    this.mainStepper?.selectionChange.pipe(takeUntil(this.destroy$)).subscribe(({ previouslySelectedIndex }) => {
      const subFormKey = `step${previouslySelectedIndex + 1}`;
      this.getStepOfForm(subFormKey).markAllAsTouched();
    });

    if (this.personalDataForm) {
      setTimeout(() => {
        this.registerValueChangesHandler();
        this.addStep4ValidationChanges(this.getStepOfForm('step4').controls);
      });
    }

    if (this.mode !== 'foreigner') {
      return;
    }
    const wasLoggedInBefore = this.localStorage.getFirstLogin();
    const hasSeenPersonalDataForm = this.localStorage.getSeenPersonalDataForm(this.userProcess.id);

    if (!wasLoggedInBefore) {
      await lastValueFrom(
        this.introModal
          .open({ messageKey: MODAL_MESSAGE.USER_WELCOME, showActionButtons: false })
          .afterClosed()
          .pipe(take(1))
      );
    }

    if (!wasLoggedInBefore && this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      await lastValueFrom(this.layoutService.startGuide(GUIDE_NAME.FIRST_TIME, this.translateService).pipe(take(1)));
    }

    if (!hasSeenPersonalDataForm) {
      await lastValueFrom(
        this.introModal
          .open({ messageKey: MODAL_MESSAGE.PERSONAL_DETAILS_INSTRUCTIONS, showActionButtons: false })
          .afterClosed()
          .pipe(take(1))
      );
    }

    if (!hasSeenPersonalDataForm && this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      await this.startPersonalDataFormGuide(GUIDE_NAME.PERSONAL_DATA_FORM, 1);
    }

    this.localStorage.setFirstLogin();
    this.localStorage.setSeenPersonalDataForm(this.userProcess.id, true);

    this.modeChangedModal.openIfPostModeAndNotSeen({ userProcess: this.userProcess });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public async initGuide(name: GUIDE_NAME, guideOfStep: number): Promise<void> {
    if (this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      await lastValueFrom(
        this.introModal
          .open({ messageKey: MODAL_MESSAGE.PERSONAL_DETAILS_INSTRUCTIONS, showActionButtons: false })
          .afterClosed()
          .pipe(take(1), takeUntil(this.destroy$))
      );
      this.startPersonalDataFormGuide(name, guideOfStep, PROCESS_CATEGORY.TEMP_PREMIT);
    }
  }

  public async startPersonalDataFormGuide(
    name: GUIDE_NAME,
    guideOfStep: number,
    processCategory?: PROCESS_CATEGORY
  ): Promise<GuideFinishedOpts> {
    if (this.mainStepper.selectedIndex + 1 !== guideOfStep) {
      await lastValueFrom(this.mainStepper.animationDone.pipe(take(1), takeUntil(this.destroy$)));
    }

    const guideFinishedOpts = await lastValueFrom(
      this.layoutService
        .startGuide(name, this.translateService, processCategory)
        .pipe(take(1), takeUntil(this.destroy$))
    );

    this.localStorage.setPersonalDetailsFormStepSeen(guideOfStep, true);
    return guideFinishedOpts;
  }

  public stepAnimationDone(): void {
    const { selectedIndex } = this.mainStepper;
    const indexToId = [
      'applicantName',
      'applicantMaritalStatus',
      'meansOfSubsistence-0',
      'inPolandAtApplicationSubmition-0',
      'applicantNoFamilyMembersInPoland-0',
    ];
    const firstElemId = indexToId[selectedIndex];
    const firstElem = document.getElementById(firstElemId);

    if (firstElem && this.mode === 'foreigner') {
      setTimeout(() => {
        firstElem.focus({ preventScroll: true });
        firstElem.dispatchEvent(new Event('focus'));
        firstElem.scrollIntoView({ block: 'center', behavior: 'auto' });
        // if element ID contains '-' then it means that it is a radio button
        // if it is a radio button then we want to add 'cdk-keyboard-focused'
        // to enforce having different appearance
        if (firstElemId.includes('-')) {
          firstElem.classList.add('cdk-keyboard-focused');
        }
      }, 50);
    }
  }

  public getStepOfForm(stepName: string): FormGroup {
    return this.personalDataForm.get(stepName) as FormGroup;
  }

  // Validating all of user inputs and setting stepState of all steps
  public saveAndProceed(): void {
    this.personalDataForm.markAllAsTouched();
    this.personalDataForm.markAsDirty();
    this.confirmDetails.emit();
  }

  private registerValueChangesHandler(): void {
    this.personalDataForm.valueChanges.pipe(skip(1), debounceTime(500), takeUntil(this.destroy$)).subscribe(changes => {
      const dataToSend = {} as any;
      const stepsGroups = { ...changes };
      Object.values(stepsGroups).forEach(group => {
        Object.entries(group).forEach(([fieldName, fieldValue]: [string, any]) => {
          if (fieldValue?._isAMomentObject) {
            dataToSend[fieldName] = fieldValue.format(fullDateFormat);
          } else {
            dataToSend[fieldName] = fieldValue;
          }

          if (fieldName === 'descriptionHeight' && fieldValue) {
            dataToSend['descriptionHeight'] = Math.floor(fieldValue);
          }
        });
      });

      this.correctlyFilledSteps = 0;
      Object.values(this.personalDataForm.controls).forEach(formGroup => {
        if (formGroup.valid) {
          this.correctlyFilledSteps += 1;
        }
      });
      this.percentageOfCompletness = Math.ceil((this.correctlyFilledSteps / this.totalStepsInForm) * 100);
      setTimeout(() => this.layoutService.progressPercentage$.next(this.percentageOfCompletness));

      const personalDetails = this.userProcess.personalDetails;
      const differences = [] as any;
      Object.keys(this.userProcess.personalDetails).forEach((udk: any) => {
        const original = personalDetails[udk as keyof PersonalDetails];
        const changed = dataToSend[udk];
        if (dataToSend[udk] !== undefined && !isEqual(original, changed)) {
          differences.push({
            key: udk,
            original,
            changed,
          });
        }
      });

      if (!differences.length) {
        return;
      }

      this.updateDetails.emit(dataToSend);
    });
  }

  private addStep4ValidationChanges(controls: { [key: string]: AbstractControl }): void {
    const inPolandAtApplicationSubmition = controls.inPolandAtApplicationSubmition.value;
    this.toggleRequiredOfCurrentlyLivingInPolandAndBasisOfStay(inPolandAtApplicationSubmition);

    const shouldPreviousVisitsBeRequired = controls.noPreviousVisits.value === false;
    this.toggleRequiredOfPreviousVisits(shouldPreviousVisitsBeRequired);

    const noLastTravelsOutsidePolandValue = controls.noLastTravelsOutsidePoland.value;
    this.toggleRequiredOfLastTravelsOutsidePoland(noLastTravelsOutsidePolandValue);
    const legalBasisOfStayValue = controls.legalBasisOfStay.value;
    this.toggleLegalBasisOfStayComment(legalBasisOfStayValue);

    // const lastEntryDate = controls.lastEntryDate.value;
    // Only when lastEntryDate set to some date, set noPreviousVisits to false because:
    // User entered PL atleast once so he must provide previousVisits info
    // When lastEntryDate set to null/false, do nothing with noPreviousVistis field
    // if (!!lastEntryDate) {
    // noPreviousVisits = false - double negation - user HAD PREVIOUS VISITS
    // controls.noPreviousVisits.setValue(false);
    // }
    // this.toggleRequiredOfPreviousVisits(!lastEntryDate);

    controls.inPolandAtApplicationSubmition.valueChanges
      .pipe(delay(50), takeUntil(this.destroy$))
      .subscribe(inPLAtSubmition => {
        this.toggleRequiredOfCurrentlyLivingInPolandAndBasisOfStay(inPLAtSubmition);
      });

    controls.currentlyLivingInPoland.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.clearBasisOfStay();
    });

    controls.noLastTravelsOutsidePoland.valueChanges
      .pipe(delay(50), takeUntil(this.destroy$))
      .subscribe(noLastTravels => {
        this.toggleRequiredOfLastTravelsOutsidePoland(noLastTravels);
      });

    controls.noPreviousVisits.valueChanges.pipe(delay(50), takeUntil(this.destroy$)).subscribe(noPreviousVisits => {
      const previousVisitsRequired = noPreviousVisits === false;
      this.toggleRequiredOfPreviousVisits(previousVisitsRequired);
    });

    controls.legalBasisOfStay.valueChanges.pipe(delay(50), takeUntil(this.destroy$)).subscribe(legalBasisOfStay => {
      this.toggleLegalBasisOfStayComment(legalBasisOfStay);
    });

    // We don't care about last entry date now
    // controls.lastEntryDate.valueChanges.pipe(
    //   delay(50), takeUntil(this.destroy$),
    // ).subscribe(entryDate => {
    // if (!!entryDate) {
    //   controls.noPreviousVisits.setValue(false);
    // }
    // });
  }

  private toggleRequiredOfCurrentlyLivingInPolandAndBasisOfStay(inPolandAtApplicationSubmition: boolean): void {
    const step4Controls = this.getStepOfForm('step4').controls;
    if (!inPolandAtApplicationSubmition) {
      step4Controls.currentlyLivingInPoland.setValidators([isBooleanValidator]);
      step4Controls.currentlyLivingInPoland.reset(null);

      step4Controls.legalBasisOfStay.setValidators([Validators.maxLength(1000)]);
      step4Controls.legalBasisOfStay.reset(null);

      step4Controls.residenceVisaExpiryDate.setValidators([customDateValidator]);
      step4Controls.residenceVisaExpiryDate.reset(null);

      step4Controls.lastEntryDate.setValidators([customDateValidator]);
      step4Controls.lastEntryDate.reset(null);
    }

    if (inPolandAtApplicationSubmition) {
      step4Controls.currentlyLivingInPoland.setValidators([Validators.required, isBooleanValidator]);

      step4Controls.legalBasisOfStay.setValidators([Validators.required, Validators.maxLength(1000)]);
      step4Controls.residenceVisaExpiryDate.setValidators([Validators.required, customDateValidator]);
      step4Controls.lastEntryDate.setValidators([Validators.required, customDateValidator]);
    }

    step4Controls.currentlyLivingInPoland.updateValueAndValidity();

    step4Controls.legalBasisOfStay.updateValueAndValidity();
    step4Controls.residenceVisaExpiryDate.updateValueAndValidity();
    step4Controls.lastEntryDate.updateValueAndValidity();

    step4Controls.previousVisitsArr.updateValueAndValidity();
  }

  private clearBasisOfStay(): void {
    const step4Controls = this.getStepOfForm('step4').controls;

    step4Controls.legalBasisOfStay.reset(null);
    step4Controls.residenceVisaExpiryDate.reset(null);
    step4Controls.lastEntryDate.reset(null);
    step4Controls.legalBasisOfStayComment.reset(null);
  }

  private toggleRequiredOfPreviousVisits(shouldBeRequired: boolean): void {
    const step4Controls = this.getStepOfForm('step4').controls;
    if (shouldBeRequired) {
      step4Controls.previousVisitsArr.setValidators([
        Validators.required,
        // Validators.maxLength(1000),
        // Validators.pattern(patterns.PLTextValidator),
      ]);
    }

    if (!shouldBeRequired) {
      step4Controls.previousVisitsArr.setValidators([
        // Validators.maxLength(1000),
        // Validators.pattern(patterns.PLTextValidator),
      ]);
    }

    step4Controls.previousVisitsArr.updateValueAndValidity();
  }

  private toggleRequiredOfLastTravelsOutsidePoland(noLastTravels: boolean): void {
    const step4Controls = this.getStepOfForm('step4').controls;
    // if noLastTravels === false then user HAS TO PROVIDE LAST TRAVELS
    if (!noLastTravels) {
      step4Controls.lastTravelsOutsidePoland.setValidators([
        Validators.required,
        Validators.maxLength(5000),
        // Validators.pattern(patterns.PLTextValidator),
      ]);
    }

    // if noLastTravels === true then user should not provide any travels
    if (noLastTravels) {
      step4Controls.lastTravelsOutsidePoland.setValidators([
        Validators.maxLength(5000),
        // Validators.pattern(patterns.PLTextValidator),
      ]);
    }

    step4Controls.lastTravelsOutsidePoland.updateValueAndValidity();
  }

  private toggleLegalBasisOfStayComment(legalBasisOfStayValue: string): void {
    const step4Controls = this.getStepOfForm('step4').controls;

    if (legalBasisOfStayValue !== 'inna podstawa') {
      step4Controls.legalBasisOfStayComment.setValidators([
        // Validators.pattern(patterns.PLTextValidator)
        Validators.maxLength(1000),
      ]);
    }

    if (legalBasisOfStayValue === 'inna podstawa') {
      step4Controls.legalBasisOfStayComment.setValidators([
        Validators.required,
        Validators.maxLength(1000),
        // Validators.pattern(patterns.PLTextValidator)
      ]);
    }

    step4Controls.legalBasisOfStayComment.updateValueAndValidity();
  }

  protected readonly PROCESS_CATEGORY = PROCESS_CATEGORY;
}

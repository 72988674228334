export const environment = {
  production: false,
  API_URL: 'https://kartapobytu.dev/carta-api',
  SOCKET_URL: 'https://kartapobytu.dev',
  version: require('../../package.json').version + '.dev',
  autAnimationTime: 3,
  PAGE_TITLE: 'Karta Pobytu - Dev',

  GOOGLE_ANALYTICS_ENABLED: true,
  GOOGLE_ANALYTICS_GTAG: 'G-95BMVX23CS',
};

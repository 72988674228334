import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { IconRegistryService } from '@core/icon-registry/icon-registry.service';
import { SessionTimeoutService } from '@core/session-timeout.service';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { CommonsFacade } from '@state/commons';
import * as moment from 'moment-timezone';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@shared/analytics';
import { environment } from '@environment';
import { filter } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  private APIPingInterval = 45000;
  private APIPingIntervalID: any;

  private APIErrorShown = false;

  private GOOGLE_ANALYTICS_ENABLED = environment.GOOGLE_ANALYTICS_ENABLED;
  private PAGE_TITLE = environment.PAGE_TITLE;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly iconRegistry: IconRegistryService,
    private readonly sessionTimeout: SessionTimeoutService,
    private readonly commonsFacade: CommonsFacade,
    private readonly snackService: SnackbarService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly titleService: Title
  ) {
    this.iconRegistry.registerIcons();
  }

  public ngOnInit(): void {
    moment.tz.setDefault('Europe/Warsaw');

    this.titleService.setTitle(this.PAGE_TITLE);

    this.deviceInfoService.startObservingDevice();
    this.analyticsService.initGoogleAnalytics();

    if (this.GOOGLE_ANALYTICS_ENABLED) {
      this.handleRouteEvents();
    }
  }

  public ngAfterViewInit(): void {
    this.document.documentElement.style.setProperty('--font-factor', '0px');

    this.commonsFacade.lastAPIPingSuccessfull$.subscribe(connected => {
      if (connected) {
        if (this.APIErrorShown) {
          this.snackService.close();
          this.snackService.showInfo('NT.SERVER_CONNECTION_RESTORED');
          this.APIErrorShown = false;
        }
        return;
      }

      if (!this.APIErrorShown) {
        this.snackService.close();
        this.snackService.showError('NT.SERVER_CONNECTION_LOST');
        this.APIErrorShown = true;
      }
    });

    // Initial APICheck
    setTimeout(() => this.commonsFacade.pingApi(), 3000);

    // Checking connection at interval
    setTimeout(() => this.startPing(), this.APIPingInterval);

    const loaderEl = this.document.getElementById('main-app-loader-container');
    loaderEl.parentElement.removeChild(loaderEl);

    this.sessionTimeout.setupIdleWatch();
  }

  private startPing(): void {
    this.APIPingIntervalID = setInterval(() => {
      this.commonsFacade.pingApi();
    }, this.APIPingInterval);
  }

  handleRouteEvents() {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        debounceTime(500)
      )
      .subscribe((event: NavigationEnd) => {
        this.analyticsService.trackEvent('user_event', 'user_changed_url', {
          URL: event.urlAfterRedirects,
        });
      });
  }
}

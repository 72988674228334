import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { isEqual } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, lastValueFrom } from 'rxjs';
import { debounceTime, delay, skip, take, takeUntil } from 'rxjs/operators';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatButton } from '@angular/material/button';
import { AvailableLanguages, patterns } from '@constants';
import { LocalStorageService } from '@core/local-storage.service';
import {
  AllDeviceInfo,
  GUIDE_NAME,
  GuideFinishedOpts,
  PROCESS_CATEGORY,
  PersonalDetails,
  User,
  UserProcess,
} from '@interfaces';
import { LayoutService } from '@layout/layout.service';
import { customDateValidator, isBooleanValidator, isNumericValidator } from '@shared/custom-validators';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { IntroModalService, MODAL_MESSAGE } from '@shared/intro-modal/intro-modal.service';
import { ModeChangedModalService } from '@shared/mode-changed-modal/mode-changed-modal.service';

type DateFormats = {
  [key: string]: string;
};

@Component({
  selector: 'app-perm-stay-form',
  templateUrl: './perm-stay-form.component.html',
  styleUrls: ['./perm-stay-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class PermStayFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('mainStepper') mainStepper: MatStepper;
  @ViewChild('stepperContainer') stepperContainer: ElementRef;
  @ViewChild('saveAndProceedButton') saveAndProceedButton: MatButton;

  @Input() user: User;
  @Input() userProcess: UserProcess;
  @Input() currentLang: AvailableLanguages;
  @Input() loadingUserProcess: boolean;
  @Input() errorMessage: string;
  @Input() mode: 'foreigner' | 'employee';

  @Input() correctlyFilledSteps: number;
  @Input() personalDataForm: FormGroup;
  @Input() percentageOfCompletness: number;
  @Input() totalStepsInForm: number;
  @Input() dateFormatsForFields: DateFormats;

  @Output() updateDetails = new EventEmitter<PersonalDetails>();
  @Output() confirmDetails = new EventEmitter<void>();
  @Output() confirmVoivoChange = new EventEmitter<void>();
  @Output() toggleVerificationOfPersonalDetails = new EventEmitter();

  public stepper: MatStepper;
  public GUIDE_NAME = GUIDE_NAME;
  public deviceInfo: AllDeviceInfo;

  constructor(
    private readonly translateService: TranslateService,
    private readonly layoutService: LayoutService,
    private readonly introModal: IntroModalService,
    private readonly localStorage: LocalStorageService,
    private readonly modeChangedModal: ModeChangedModalService,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });
  }

  public async ngAfterViewInit(): Promise<void> {
    this.mainStepper?.selectionChange.pipe(takeUntil(this.destroy$)).subscribe(({ previouslySelectedIndex }) => {
      const subFormKey = `step${previouslySelectedIndex + 1}`;
      this.getStepOfForm(subFormKey).markAllAsTouched();
    });

    if (this.personalDataForm) {
      setTimeout(() => {
        this.registerValueChangesHandler();
        this.addStep1ValidationChanges(this.getStepOfForm('step1').controls);
        this.addStep4ValidationChanges(this.getStepOfForm('step4').controls);
      });
    }

    if (this.mode !== 'foreigner') {
      return;
    }
    const wasLoggedInBefore = this.localStorage.getFirstLogin();
    const hasSeenPersonalDataForm = this.localStorage.getSeenPersonalDataForm(this.userProcess.id);

    if (!wasLoggedInBefore) {
      await lastValueFrom(
        this.introModal
          .open({ messageKey: MODAL_MESSAGE.USER_WELCOME, showActionButtons: false })
          .afterClosed()
          .pipe(take(1))
      );
    }

    if (!wasLoggedInBefore && this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      await lastValueFrom(this.layoutService.startGuide(GUIDE_NAME.FIRST_TIME, this.translateService).pipe(take(1)));
    }

    if (!hasSeenPersonalDataForm) {
      await lastValueFrom(
        this.introModal
          .open({ messageKey: MODAL_MESSAGE.PERSONAL_DETAILS_INSTRUCTIONS, showActionButtons: false })
          .afterClosed()
          .pipe(take(1))
      );
    }

    if (!hasSeenPersonalDataForm && this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      await this.startPersonalDataFormGuide(GUIDE_NAME.PERSONAL_DATA_FORM, 1);
    }

    this.localStorage.setFirstLogin();
    this.localStorage.setSeenPersonalDataForm(this.userProcess.id, true);

    this.modeChangedModal.openIfPostModeAndNotSeen({ userProcess: this.userProcess });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public async initGuide(name: GUIDE_NAME, guideOfStep: number): Promise<void> {
    if (this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      await lastValueFrom(
        this.introModal
          .open({ messageKey: MODAL_MESSAGE.PERSONAL_DETAILS_INSTRUCTIONS, showActionButtons: false })
          .afterClosed()
          .pipe(take(1), takeUntil(this.destroy$))
      );
      this.startPersonalDataFormGuide(name, guideOfStep, PROCESS_CATEGORY.PERM_STAY);
    }
  }

  public async startPersonalDataFormGuide(
    name: GUIDE_NAME,
    guideOfStep: number,
    processCategory?: PROCESS_CATEGORY
  ): Promise<GuideFinishedOpts> {
    if (this.mainStepper.selectedIndex + 1 !== guideOfStep) {
      await lastValueFrom(this.mainStepper.animationDone.pipe(take(1), takeUntil(this.destroy$)));
    }

    const guideFinishedOpts = await lastValueFrom(
      this.layoutService
        .startGuide(name, this.translateService, processCategory)
        .pipe(take(1), takeUntil(this.destroy$))
    );

    this.localStorage.setPersonalDetailsFormStepSeen(guideOfStep, true);
    return guideFinishedOpts;
  }

  public stepAnimationDone(): void {
    const { selectedIndex } = this.mainStepper;
    const indexToId = [
      'applicantName',
      'applicantMaritalStatus',
      'meansOfSubsistence-0',
      'inPolandAtApplicationSubmition-0',
      'applicantNoFamilyMembersInPoland-0',
    ];
    const firstElemId = indexToId[selectedIndex];
    const firstElem = document.getElementById(firstElemId);

    if (firstElem && this.mode === 'foreigner') {
      setTimeout(() => {
        firstElem.focus({ preventScroll: true });
        firstElem.dispatchEvent(new Event('focus'));
        firstElem.scrollIntoView({ block: 'center', behavior: 'auto' });
        // if element ID contains '-' then it means that it is a radio button
        // if it is a radio button then we want to add 'cdk-keyboard-focused'
        // to enforce having different appearance
        if (firstElemId.includes('-')) {
          firstElem.classList.add('cdk-keyboard-focused');
        }
      }, 50);
    }
  }

  public getStepOfForm(stepName: string): FormGroup {
    return this.personalDataForm.get(stepName) as FormGroup;
  }

  // Validating all of user inputs and setting stepState of all steps
  public saveAndProceed(): void {
    this.personalDataForm.markAllAsTouched();
    this.personalDataForm.markAsDirty();
    this.confirmDetails.emit();
  }

  private registerValueChangesHandler(): void {
    this.personalDataForm.valueChanges.pipe(skip(1), debounceTime(500), takeUntil(this.destroy$)).subscribe(changes => {
      const dataToSend = {} as any;
      const stepsGroups = { ...changes };
      Object.values(stepsGroups).forEach(group => {
        Object.entries(group).forEach(([fieldName, fieldValue]: [string, any]) => {
          const dateFormat: string | undefined = this.dateFormatsForFields[fieldName];

          if (fieldValue?._isAMomentObject && dateFormat) {
            dataToSend[fieldName] = fieldValue.format(dateFormat);
          } else {
            dataToSend[fieldName] = fieldValue;
          }

          if (fieldName === 'descriptionHeight' && fieldValue) {
            dataToSend['descriptionHeight'] = Math.floor(fieldValue);
          }
        });
      });

      this.correctlyFilledSteps = 0;
      Object.values(this.personalDataForm.controls).forEach(formGroup => {
        if (formGroup.valid) {
          this.correctlyFilledSteps += 1;
        }
      });
      this.percentageOfCompletness = Math.ceil((this.correctlyFilledSteps / this.totalStepsInForm) * 100);
      setTimeout(() => this.layoutService.progressPercentage$.next(this.percentageOfCompletness));

      const personalDetails = this.userProcess.personalDetails;
      const differences = [] as any;
      Object.keys(this.userProcess.personalDetails).forEach((udk: any) => {
        const original = personalDetails[udk as keyof PersonalDetails];
        const changed = dataToSend[udk];
        if (dataToSend[udk] !== undefined && !isEqual(original, changed)) {
          differences.push({
            key: udk,
            original,
            changed,
          });
        }
      });

      if (!differences.length) {
        return;
      }

      this.updateDetails.emit(dataToSend);
    });
  }

  private addStep1ValidationChanges(controls: { [key: string]: AbstractControl }): void {
    const maritalStatus = controls.maritalStatus.value;
    this.toggleRequiredOfMaritalStatus(maritalStatus);

    controls.maritalStatus.valueChanges.pipe(delay(200), takeUntil(this.destroy$)).subscribe(maritalStatus => {
      this.toggleRequiredOfMaritalStatus(maritalStatus);
    });
  }

  private addStep4ValidationChanges(controls: { [key: string]: AbstractControl }): void {
    const inPolandAtApplicationSubmition = controls.inPolandAtApplicationSubmition.value;
    this.toggleRequiredOfCurrentlyLivingInPolandAndBasisOfStay(inPolandAtApplicationSubmition);

    const legalBasisOfStay = controls.legalBasisOfStay.value;
    if (legalBasisOfStay === 'VISA') {
      this.toggleRequiredOfLegalBasisOfStay('VISA');
    }
    if (legalBasisOfStay === 'SCHENGEN_COUNTRY_ISSUED_DOC') {
      this.toggleRequiredOfLegalBasisOfStay('SCHENGEN_COUNTRY_ISSUED_DOC');
    }

    controls.inPolandAtApplicationSubmition.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(inPLAtSubmition => {
      this.toggleRequiredOfCurrentlyLivingInPolandAndBasisOfStay(inPLAtSubmition);
    });

    controls.currentlyLivingInPoland.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.clearBasisOfStay();
    });

    controls.legalBasisOfStay.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(legalBasisOfStay => {
      this.toggleRequiredOfLegalBasisOfStay(legalBasisOfStay);
    });
  }

  private toggleRequiredOfMaritalStatus(maritalStatus: string): void {
    const step3Controls = this.getStepOfForm('step3').controls;

    const ALLOWED_STATUS = ['Żonaty', 'Mężatka'];

    if (!maritalStatus || !ALLOWED_STATUS.includes(maritalStatus)) {
      [
        'spouseName',
        'spouseSurname',
        'spouseDob',
        'spouseSex',
        'spouseCitizenship',
        'spouseResidenceCity',
        'spouseResidenceStreet',
        'spouseResidenceHouseNumber',
        'spouseResidencePostalCode',
        'spouseResidenceCountry',
        'spouseResidenceVoivodeshipDetailsId',
        'spouserResidenceMunicipalityId',
        'spouseResidenceDistrictId',
        'spouseResidenceCityListedId',
        'spousePreviousName',
        'spousePreviousSurname',
        'spouseResidenceApartmentNumber',
      ].forEach(fieldControlName => {
        step3Controls[fieldControlName]?.setValidators([]);
        step3Controls[fieldControlName]?.reset(null);
      });
    }

    if (maritalStatus && ALLOWED_STATUS.includes(maritalStatus)) {
      ['spouseName', 'spouseSurname'].forEach(fieldControlName => {
        step3Controls[fieldControlName]?.setValidators([
          Validators.required,
          Validators.maxLength(200),
          Validators.pattern(patterns.PLTextValidator),
        ]);
        step3Controls[fieldControlName]?.updateValueAndValidity();
      });

      [
        'spouseResidenceCountry',
        'spouseCitizenship',
        'spouseResidenceCity',
        'spouseResidenceStreet',
        'spouseResidenceHouseNumber',
        'spouseResidencePostalCode',
        'spouseResidenceVoivodeshipDetailsId',
        'spouserResidenceMunicipalityId',
        'spouseResidenceDistrictId',
        'spouseResidenceCityListedId',
        'spousePreviousName',
        'spousePreviousSurname',
        'spouseResidenceApartmentNumber',
      ].forEach(fieldControlName => {
        step3Controls[fieldControlName]?.setValidators([
          Validators.maxLength(200),
          Validators.pattern(patterns.PLTextValidator),
        ]);
        step3Controls[fieldControlName]?.updateValueAndValidity();
      });

      step3Controls.spouseDob.setValidators([Validators.required, customDateValidator]);
      step3Controls.spouseSex.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(200)]);
    }
  }

  private toggleRequiredOfCurrentlyLivingInPolandAndBasisOfStay(inPolandAtApplicationSubmition: boolean): void {
    const step4Controls = this.getStepOfForm('step4').controls;

    if (!inPolandAtApplicationSubmition) {
      step4Controls.currentlyLivingInPoland.setValidators([isBooleanValidator]);
      step4Controls.currentlyLivingInPoland.reset(null);

      step4Controls.legalBasisOfStay.setValidators([Validators.maxLength(1000)]);
      step4Controls.legalBasisOfStay.reset(null);

      step4Controls.residenceVisaExpiryDate.setValidators([customDateValidator]);
      step4Controls.residenceVisaExpiryDate.reset(null);

      step4Controls.lastEntryDate.setValidators([customDateValidator]);
      step4Controls.lastEntryDate.reset(null);

      step4Controls.purposeOfStay.setValidators([Validators.maxLength(100)]);
      step4Controls.purposeOfStay.reset(null);
    }

    if (inPolandAtApplicationSubmition) {
      step4Controls.currentlyLivingInPoland.setValidators([Validators.required, isBooleanValidator]);

      step4Controls.legalBasisOfStay.setValidators([Validators.required, Validators.maxLength(1000)]);
      step4Controls.residenceVisaExpiryDate.setValidators([Validators.required, customDateValidator]);
      step4Controls.lastEntryDate.setValidators([Validators.required, customDateValidator]);
      step4Controls.purposeOfStay.setValidators([Validators.required, Validators.maxLength(100)]);

      step4Controls.previousVisitsArr.setValidators([Validators.required]);
    }

    step4Controls.currentlyLivingInPoland.updateValueAndValidity();

    step4Controls.legalBasisOfStay.updateValueAndValidity();
    step4Controls.residenceVisaExpiryDate.updateValueAndValidity();
    step4Controls.lastEntryDate.updateValueAndValidity();
    step4Controls.purposeOfStay.updateValueAndValidity();

    step4Controls.previousVisitsArr.updateValueAndValidity();
  }

  private clearBasisOfStay(): void {
    const step4Controls = this.getStepOfForm('step4').controls;

    step4Controls.legalBasisOfStay.reset(null);
    step4Controls.residenceVisaExpiryDate.reset(null);
    step4Controls.lastEntryDate.reset(null);
    step4Controls.legalBasisOfStayComment.reset(null);
    step4Controls.purposeOfStay.reset(null);
  }

  private toggleRequiredOfLegalBasisOfStay(legalBasisOfStay: string): void {
    const step4Controls = this.getStepOfForm('step4').controls;

    if (legalBasisOfStay === 'VISA') {
      step4Controls.stayVisaType.setValidators([Validators.required, Validators.maxLength(100)]);
      step4Controls.stayVisaSeries.setValidators([Validators.required, Validators.maxLength(2)]);
      step4Controls.stayVisaNumber.setValidators([Validators.required, Validators.maxLength(10), isNumericValidator]);
      step4Controls.stayVisaIssueDate.setValidators([Validators.required, customDateValidator]);
      step4Controls.stayVisaExpiryDate.setValidators([Validators.required, customDateValidator]);
      step4Controls.stayVisaPeriodOfStay.setValidators([Validators.required, Validators.min(1), Validators.max(9999)]);
      step4Controls.stayVisaIssuingAuthority.setValidators([Validators.required, Validators.maxLength(100)]);
    }

    if (legalBasisOfStay !== 'VISA') {
      step4Controls.stayVisaType.reset(null);
      step4Controls.stayVisaSeries.reset(null);
      step4Controls.stayVisaNumber.reset(null);
      step4Controls.stayVisaIssueDate.reset(null);
      step4Controls.stayVisaExpiryDate.reset(null);
      step4Controls.stayVisaPeriodOfStay.reset(null);
      step4Controls.stayVisaIssuingAuthority.reset(null);

      step4Controls.stayVisaType.setValidators([]);
      step4Controls.stayVisaSeries.setValidators([]);
      step4Controls.stayVisaNumber.setValidators([]);
      step4Controls.stayVisaIssueDate.setValidators([]);
      step4Controls.stayVisaExpiryDate.setValidators([]);
      step4Controls.stayVisaPeriodOfStay.setValidators([]);
      step4Controls.stayVisaIssuingAuthority.setValidators([]);
    }

    if (legalBasisOfStay === 'SCHENGEN_COUNTRY_ISSUED_DOC') {
      step4Controls.stayEntitlingDocumentIssuedBy.setValidators([Validators.required, Validators.maxLength(100)]);
      step4Controls.stayEntitlingDocumentIssueDate.setValidators([Validators.required, customDateValidator]);

      step4Controls.entryDocumentSeries.setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern(patterns.PLTextValidator),
      ]);
      step4Controls.entryDocumentNumber.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20),
        isNumericValidator,
      ]);
      step4Controls.entryDocumentIssueDate.setValidators([Validators.required, customDateValidator]);
      step4Controls.entryDocumentExpiryDate.setValidators([Validators.required, customDateValidator]);
      step4Controls.entryDocumentIssuingAuthority.setValidators([Validators.required, Validators.maxLength(100)]);
    }

    if (legalBasisOfStay !== 'SCHENGEN_COUNTRY_ISSUED_DOC') {
      step4Controls.stayEntitlingDocumentIssuedBy.reset(null);
      step4Controls.stayEntitlingDocumentIssueDate.reset(null);
      step4Controls.entryDocumentSeries.reset(null);
      step4Controls.entryDocumentNumber.reset(null);
      step4Controls.entryDocumentIssueDate.reset(null);
      step4Controls.entryDocumentExpiryDate.reset(null);
      step4Controls.entryDocumentIssuingAuthority.reset(null);

      step4Controls.stayEntitlingDocumentIssuedBy.setValidators([]);
      step4Controls.stayEntitlingDocumentIssueDate.setValidators([]);
      step4Controls.entryDocumentSeries.setValidators([]);
      step4Controls.entryDocumentNumber.setValidators([]);
      step4Controls.entryDocumentIssueDate.setValidators([]);
      step4Controls.entryDocumentExpiryDate.setValidators([]);
      step4Controls.entryDocumentIssuingAuthority.setValidators([]);
    }

    step4Controls.stayVisaType.markAsUntouched();
    step4Controls.stayVisaType.updateValueAndValidity();

    step4Controls.stayVisaSeries.markAsUntouched();
    step4Controls.stayVisaSeries.updateValueAndValidity();

    step4Controls.stayVisaNumber.markAsUntouched();
    step4Controls.stayVisaNumber.updateValueAndValidity();

    step4Controls.stayVisaIssueDate.markAsUntouched();
    step4Controls.stayVisaIssueDate.updateValueAndValidity();

    step4Controls.stayVisaExpiryDate.markAsUntouched();
    step4Controls.stayVisaExpiryDate.updateValueAndValidity();

    step4Controls.stayVisaPeriodOfStay.markAsUntouched();
    step4Controls.stayVisaPeriodOfStay.updateValueAndValidity();

    step4Controls.stayVisaIssuingAuthority.markAsUntouched();
    step4Controls.stayVisaIssuingAuthority.updateValueAndValidity();

    step4Controls.stayEntitlingDocumentIssuedBy.markAsUntouched();
    step4Controls.stayEntitlingDocumentIssuedBy.updateValueAndValidity();

    step4Controls.stayEntitlingDocumentIssueDate.markAsUntouched();
    step4Controls.stayEntitlingDocumentIssueDate.updateValueAndValidity();

    step4Controls.entryDocumentSeries.markAsUntouched();
    step4Controls.entryDocumentSeries.updateValueAndValidity();

    step4Controls.entryDocumentNumber.markAsUntouched();
    step4Controls.entryDocumentNumber.updateValueAndValidity();

    step4Controls.entryDocumentIssueDate.markAsUntouched();
    step4Controls.entryDocumentIssueDate.updateValueAndValidity();

    step4Controls.entryDocumentExpiryDate.markAsUntouched();
    step4Controls.entryDocumentExpiryDate.updateValueAndValidity();

    step4Controls.entryDocumentIssuingAuthority.markAsUntouched();
    step4Controls.entryDocumentIssuingAuthority.updateValueAndValidity();
  }
}

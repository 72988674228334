<div id="follow-up-email">
  <h1 mat-dialog-title>
    {{ 'FOLLOW_UP.FOLLOW_UP_HEADER' | translate }} - <b>{{ this.data.supportChat.supportedUser.name }}</b> ({{
      this.data.supportChat.supportedUser.language.name
    }})
  </h1>
  <div mat-dialog-content>
    <p>{{ 'FOLLOW_UP.FOLLOW_UP_CHOOSE_LANGUAGE' | translate }}</p>
    <mat-form-field appearance="legacy" *ngIf="languages$ | async as languages">
      <mat-select [(value)]="selectedLang" (selectionChange)="confirmChangeLanguageDialog($event.value)">
        <mat-option *ngFor="let language of languages" [value]="language.key">
          {{ 'LANGUAGE.' + language.key.toUpperCase() | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="dialog_follow_up_title">
      <mat-label>{{ 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_TITLE' | translate }}</mat-label>
      <textarea matInput [(ngModel)]="messageTranslations.TITLE_VALUE"></textarea>
    </mat-form-field>

    <mat-form-field class="dialog_follow_up_subtitle">
      <mat-label>{{ 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_SUBTITLE' | translate }}</mat-label>
      <textarea matInput [(ngModel)]="messageTranslations.SUBTITLE_VALUE"></textarea>
    </mat-form-field>

    <mat-form-field class="dialog_follow_up_footer">
      <mat-label>{{ 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_FOOTER' | translate }}</mat-label>
      <textarea matInput [(ngModel)]="messageTranslations.FOOTER_VALUE"></textarea>
    </mat-form-field>

    <div class="email-center">
      <div class="email-container">
        <div class="email-header">
          <div class="email-to"><span class="label">To:</span> {{ data.supportChat.supportedUser?.email }}</div>
          <div class="email-subject"><span class="label">Subject:</span> {{ messageTranslations.TITLE_VALUE }}</div>
        </div>
        <div class="email-body">
          <span>
            <b>
              <h5>
                {{ messageTranslations.WELCOME }}
              </h5>
            </b>
          </span>
          <span>{{ messageTranslations.SUBTITLE_VALUE }}</span>
          <div class="button-span">
            <button class="login-button" nz-button nzType="primary" nzBlock>
              {{ messageTranslations.BUTTON }}
            </button>
          </div>
          <div>
            <span class="button-not-work-span">{{ messageTranslations.BUTTON_NOT_WORK }}</span>
          </div>
          <div>
            <span class="url-span">https://app.kartapobytu.pl</span>
          </div>
          <div class="footer-div">
            <span class="footer-span">{{ messageTranslations.FOOTER_VALUE }}</span>
          </div>
        </div>
        <div class="email-footer"></div>
      </div>
    </div>
  </div>
  <span class="preview-span">{{ 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_PREVIEW' | translate }}</span>
  <div mat-dialog-actions>
    <button mat-button class="dialog_follow_up_cancel" (click)="closeDialog()">
      {{ 'FOLLOW_UP.FOLLOW_UP_CANCEL' | translate }}
    </button>
    <button mat-button class="dialog_follow_up_send" (click)="sendMessage()">
      {{ 'FOLLOW_UP.FOLLOW_UP_SEND' | translate }}
    </button>
  </div>
</div>
